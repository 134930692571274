import React from 'react'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import { graphql } from 'gatsby'
import StyledGallery from '../../../components/StyledGallery/StyledGallery'
import ReturnToPrevPage from '../../../components/ReturnToPrevPage/ReturnToPrevPage'
import Img from 'gatsby-image'
import MonumentContentWrapper from '../../../components/MonumentContentWrapper/MonumentContentWrapper'
import { Header1 } from '../../../components/Headers/Headers'
import SEO from '../../../components/seo'
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer'

const MogilaZolnierskaOkuniewPage = ({ data }) => {
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  return (
    <ArchiveWrapper>
      <SEO title="Mogiła żołnierska z 1920 roku w Okuniewie" />
      <ReturnToPrevPage to={'/gminy/halinow'}>
        Materiały dotyczące gminy Halinów
      </ReturnToPrevPage>
      <Header1>Mogiła żołnierska z 1920 roku w Okuniewie</Header1>
      <Img fluid={data.file.childImageSharp.fluid} />
      <MonumentContentWrapper>
        <p>
          Na okuniewskim cmentarzu parafialnym znajduje się zbiorowa mogiła
          kilku żołnierzy wojny polsko-bolszewickiej. W połowie sierpnia 1920
          roku w okolicach Okuniewa prowadzone były różnorakie działania
          związane z trwającą Bitwą Warszawską. Polegli oraz zmarli w wyniku ran
          odniesionych podczas działań mających miejsce w dniach od 13 do 18
          sierpnia 1920 roku.
        </p>
        <p>
          W mogile spoczywają: Stanisław Zając (saper), Ignacy Kowalczyk
          (żołnierz z 21 Warszawskiego Pułku Piechoty), Stanisław Gnojko
          (żołnierz z 50 Pułku Piechoty Strzelców Kresowych) oraz Jan
          Kowalkowski. W 1928 roku ufundowano pomnik nagrobny poświęcony
          spoczywającym tam Bojownikom za wolność Ojczyzny.
        </p>
      </MonumentContentWrapper>
      <StyledGallery images={images} />
    </ArchiveWrapper>
  )
}

export const query = graphql`
  {
    file(
      name: { regex: "/featured/" }
      relativePath: { regex: "/halinow/okuniew-obelisk/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allFile(filter: { relativePath: { regex: "/halinow/okuniew-obelisk/" } }) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default MogilaZolnierskaOkuniewPage
